import React from "react";
import { withRouter } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import FadeIn from "react-fade-in";

const videos = [
  {
    nav: "Introduction",
    video: "01_Introduction",
    length: "0:26"
  },
  {
    nav: "Account Creation",
    video: "02_AccountCreation",
    length: "1:56"
  },
  {
    nav: "Creating First Organization & Ensemble",
    video: "03_CreatingFirstOrganizationAndEnsemble",
    length: "1:05"
  },
  {
    nav: "Organization & Ensemble Pages Description",
    video: "04_OrganizationAndEnsemblePages",
    length: "4:30"
  },
  {
    nav: "Song Folders vs Part Folders",
    video: "05_SongFoldersVsPartFolders",
    length: "1:06"
  },
  {
    nav: "Adding Songs from Song Folders",
    video: "06_AddingSongsFromSongFolders",
    length: "5:29"
  },
  {
    nav: "Adding Parts from Part Folders",
    video: "07_AddingPartsFromPartFolders",
    length: "3:49"
  },
  {
    nav: "Adding Ensemble Leaders",
    video: "08_AuthenticatingEnsembleLeaders",
    length: "2:32"
  },
  {
    nav: "Performances",
    video: "09_Performances",
    length: "4:22"
  },
  {
    nav: "Playlists",
    video: "10_Playlists",
    length: "2:19"
  },
  {
    nav: "Adding Organization Members",
    video: "11_OrgMembers",
    length: "4:16"
  },
  {
    nav: "How Licenses Work",
    video: "12_HowLicensesWork",
    length: "1:06"
  },
  {
    nav: "(Advanced) One Library for Two Ensembles",
    video: "13_OneLibraryTwoEnsembles",
    length: "3:40"
  },
  {
    nav: "(Advanced) Libraries with Different Instrumentation",
    video: "14_MatchingLibraryEnsembleInstrumentation",
    length: "2:37"
  },
  {
    nav: "Making Your Purchase",
    video: "15_BuildingYourQuote",
    length: "2:47"
  },
  {
    nav: "Activating Licenses",
    video: "16_ActivatingLicenses",
    length: "0:50"
  },
  {
    nav: "Additional Purchases",
    video: "17_AdditionalPurchases",
    length: "1:27"
  },
  {
    nav: "Instrument Clips",
    video: "18_InstrumentClips",
    length: "1:58"
  },
  {
    nav: "Attendance",
    video: "19_Attendance",
    length: "3:07"
  },
  {
    nav: "Burst Ensembles",
    video: "20_BurstEnsembles",
    length: "3:11"
  },
];

let totalSeconds = 0;

for (let i = 0; i < videos.length; i++) {
  let length = videos[i].length.split(":");
  let minutes = parseInt(length[0]);
  let seconds = parseInt(length[1]);
  totalSeconds += seconds + minutes * 60;
}

const totalLength =
  parseInt(totalSeconds / 60) + ":" + parseInt(totalSeconds % 60);

class Leader extends React.Component {
  constructor() {
    super();

    let video = videos[0].video;

    // If query parameter is set, use that video
    let url = new URL(window.location.href);
    let videoParam = url.searchParams.get("video");
    if (videoParam && videos.find(v => v.video == videoParam)) {
      video = videoParam;
    }

    this.state = {
      visible: true,
      video: video
    };
  }

  render() {
    return (
      <header className="App-header no-flex">
        <Container fluid="true">
          <Row>
            <Col md="8">
              <FadeIn>
                <div className={this.state.visible ? "fadeIn" : "fadeOut"}>
                  <div className="embed-responsive embed-responsive-16by9">
                    <video
                      controls="true"
                      autoplay="true"
                      onEnded={this.videoEnded.bind(this)}
                      key={this.state.video}
                      style={{ "max-height": "95vh" }}
                    >
                      <source
                        src={
                          "https://s3.us-east-2.amazonaws.com/eflip/video2/leader/" +
                          this.state.video +
                          ".webm"
                        }
                      />
                      <source
                        src={
                          "https://s3.us-east-2.amazonaws.com/eflip/video2/leader/" +
                          this.state.video +
                          ".mp4"
                        }
                      />
                    </video>
                  </div>
                </div>
              </FadeIn>
            </Col>
            <Col md="4">
              <FadeIn>
                <Table bordered hover variant="light" style={{ "font-size": '10pt' }}>
                  <thead>
                    <tr className="navigation">
                      <th colSpan="2">Navigation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {videos.map(opt => {
                      return (
                        <tr
                          onClick={this.changeVideo.bind(this, opt.video)}
                          className={
                            this.state.video == opt.video ? "selected" : ""
                          }
                        >
                          <td className="pt-1 pb-1">{opt.nav}</td>
                          <td className="pt-1 pb-1">{opt.length}</td>
                        </tr>
                      );
                    })}
                    <tr className="navigation">
                      <td style={{ textAlign: "right" }}>
                        <b>Total: </b>
                      </td>
                      <td>{totalLength}</td>
                    </tr>
                  </tbody>
                </Table>
              </FadeIn>
            </Col>
          </Row>
        </Container>
      </header>
    );
  }

  videoEnded() {
    let changeToNextVideo = false;
    for (let i = 0; i < videos.length; i++) {
      let obj = videos[i];
      if (changeToNextVideo) {
        this.changeVideo(obj.video);
        return;
      }

      if (this.state.video == obj.video) {
        changeToNextVideo = true;
      }
    }
  }

  changeVideo(id) {
    this.setState({
      visible: false
    });
    setTimeout(() => {
      this.setState({
        visible: true,
        video: id
      });
    }, 210);

    this.props.history.push("/leader?video=" + id);
  }
}

export default withRouter(Leader);
