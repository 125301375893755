import React from "react";
import { withRouter } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import FadeIn from "react-fade-in";

const videos = [
  {
    nav: "Introduction",
    video: "Introduction",
    length: "0:27",
  },
  {
    nav: "Setup",
    video: "setup",
    length: "4:03",
  },
  {
    nav: "Music Menu",
    video: "music_menu",
    length: "1:24",
  },
  {
    nav: "Playlists",
    video: "playlists",
    length: "0:39",
  },
  {
    nav: "Song Manipulation",
    video: "song_manipulation",
    length: "1:24",
  },
  {
    nav: "Notating Music",
    video: "notation",
    length: "2:16",
  },
  {
    nav: "Settings",
    video: "settings",
    length: "1:27",
  },
  {
    nav: "Power Saving & Dark Mode",
    video: "power_and_dark",
    length: "1:14",
  },
];

let totalSeconds = 0;

for (let i = 0; i < videos.length; i++) {
  let length = videos[i].length.split(":");
  let minutes = parseInt(length[0]);
  let seconds = parseInt(length[1]);
  totalSeconds += seconds + minutes * 60;
}

const totalLength =
  parseInt(totalSeconds / 60) + ":" + parseInt(totalSeconds % 60);

class Student extends React.Component {
  constructor() {
    super();

    let video = videos[0].video;

    // If query parameter is set, use that video
    let url = new URL(window.location.href);
    let videoParam = url.searchParams.get("video");
    if (videoParam && videos.find(v => v.video == videoParam)) {
      video = videoParam;
    }


    this.state = {
      visible: true,
      video: video,
    };
  }

  render() {
    return (
      <header className="App-header no-flex">
        <Container fluid="true">
          <Row>
            <Col md="8">
              <FadeIn>
                <div className={this.state.visible ? "fadeIn" : "fadeOut"}>
                  <div className="embed-responsive embed-responsive-16by9">
                    <video
                      controls={true}
                      autoPlay={true}
                      onEnded={this.videoEnded.bind(this)}
                      key={this.state.video}
                      style={{ "max-height": "95vh" }}
                    >
                      <source
                        src={
                          "https://s3.us-east-2.amazonaws.com/eflip/video2/student/" +
                          this.state.video +
                          ".webm"
                        }
                      />
                      <source
                        src={
                          "https://s3.us-east-2.amazonaws.com/eflip/video2/student/" +
                          this.state.video +
                          ".mp4"
                        }
                      />
                    </video>
                  </div>
                </div>
              </FadeIn>
            </Col>
            <Col md="4">
              <FadeIn>
                <Table bordered hover variant="light">
                  <thead>
                    <tr className="navigation">
                      <th colSpan="2">Navigation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {videos.map((opt) => {
                      return (
                        <tr
                          onClick={this.changeVideo.bind(this, opt.video)}
                          className={
                            this.state.video == opt.video ? "selected" : ""
                          }
                        >
                          <td>{opt.nav}</td>
                          <td>{opt.length}</td>
                        </tr>
                      );
                    })}
                    <tr className="navigation">
                      <td style={{ textAlign: "right" }}>
                        <b>Total: </b>
                      </td>
                      <td>{totalLength}</td>
                    </tr>
                  </tbody>
                </Table>
              </FadeIn>
            </Col>
          </Row>
        </Container>
      </header>
    );
  }

  videoEnded() {
    let changeToNextVideo = false;
    for (let i = 0; i < videos.length; i++) {
      let obj = videos[i];
      if (changeToNextVideo) {
        this.changeVideo(obj.video);
        return;
      }

      if (this.state.video == obj.video) {
        changeToNextVideo = true;
      }
    }
  }

  changeVideo(id) {
    this.setState({
      visible: false,
    });
    setTimeout(() => {
      this.setState({
        visible: true,
        video: id,
      });
    }, 210);

    this.props.history.push("/student?video=" + id);
  }
}

export default withRouter(Student);
