import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import "./App.css";
import Main from "./screens/Main";
import ChoosePath from "./screens/ChoosePath";
import Leader from "./screens/Leader";
import Student from "./screens/Student";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

class App extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    document.title = "FlipFolder Video Portal";
  }

  render() {
    return (
      <Router>
        <div className="App">
          <Route path="/" exact component={Main} />
          <Route path="/choose_path" exact component={ChoosePath} />
          <Route path="/leader" exact component={Leader} />
          <Route path="/student" exact component={Student} />
        </div>
      </Router>
    );
  }
}

export default App;
