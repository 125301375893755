import React from "react";
import { withRouter } from "react-router-dom";

import leader from "./buttons/leader.png";
import users from "./buttons/users.png";
import FadeIn from "react-fade-in";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

class ChoosePath extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: true
    };
  }

  render() {
    return (
      <header className="App-header">
        <div className={this.state.visible ? "" : "fadeOut"}>
          <Container>
            <Row>
              <Col sm="6">
                <FadeIn delay="50">
                  <img
                    src={leader}
                    className="big-clicker"
                    onClick={this.logoClick.bind(this, "leader")}
                  />
                </FadeIn>
              </Col>
              <Col sm="6">
                <FadeIn delay="50">
                  <img
                    src={users}
                    className="big-clicker"
                    onClick={this.logoClick.bind(this, "student")}
                  />
                </FadeIn>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <FadeIn delay="1000">
                  <p>Band Leader Tutorial</p>
                </FadeIn>
              </Col>
              <Col sm="6">
                <FadeIn delay="1000">
                  <p>Band Student Tutorial</p>
                </FadeIn>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    );
  }

  logoClick(screen) {
    this.setState({
      visible: false
    });
    setTimeout(() => {
      this.props.history.push("/" + screen);
    }, 210);
  }
}

export default withRouter(ChoosePath);
