import React from "react";
import { withRouter } from "react-router-dom";

import logo from "../logo.png";
import FadeIn from "react-fade-in";

class Main extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: true
    };
  }

  render() {
    return (
      <header className="App-header">
        <div className={this.state.visible ? "" : "fadeOut"}>
          <FadeIn delay="50">
            <img
              src={logo}
              className="big-clicker"
              onClick={this.logoClick.bind(this)}
            />
          </FadeIn>
          <FadeIn delay="1000">
            <p>Click to enter learning portal</p>
          </FadeIn>
        </div>
      </header>
    );
  }

  logoClick() {
    this.setState({
      visible: false
    });
    setTimeout(() => {
      this.props.history.push("/choose_path");
    }, 210);
  }
}

export default withRouter(Main);
